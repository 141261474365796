import React from "react";
import { IoIosMail } from "react-icons/io";


export default function Footer() {
    return (
      <>
        <div className="hidden md:block w-full bg-[#151934] z-50">
            <div className="md:px-[40px] px-[40px] h-[90px] flex items-center justify-between w-full pt-4 flex-row">
                {/* Logo and Title aligned to the left */}
                <div className='flex gap-2 items-center transform -translate-y-[5px]'>
                    <img 
                        src='/images/NetreachLogo_v2.png' 
                        alt='NetReach Logo'
                        className='h-[60px]'
                    />
                    <span className='text-white font-bold text-[20px]'>NetReach</span>
                </div>

                {/* Copyright claim centered */}
                <p className="text-center absolute left-1/2 transform -translate-y-[20px] -translate-x-1/2 translate-y-[1px] text-white font-normal font-primary text-[12px] mb-[10px]">
                    Copyright © All Right Reserved. Netreach Solutions Ltd 2024
                </p>
                <div className="flex gap-1">
                <IoIosMail className="text-white"/>
                <p className="text-white transform -translate-y-[5px]">enquiries@netreach.uk</p>
                </div>
            </div>
        </div>
        <div className="block md:hidden w-full py-7 bg-[#151934]">
            <div className="md:px-[40px] px-[40px] flex items-center w-full pt-4 flex-col">
                {/* Logo and Title aligned to the left */}
                <div className='flex gap-2 items-center transform -translate-y-[5px]'>
                    <img 
                        src='/images/NetreachLogo_v2.png' 
                        alt='NetReach Logo'
                        className='h-[60px]'
                    />
                    <span className='text-white font-bold text-[20px]'>NetReach</span>
                </div>

                <div className="flex gap-1 mb-5 mt-5">
                <IoIosMail className="text-white"/>
                <p className="text-white transform -translate-y-[5px] ">enquiries@netreach.uk</p>
                </div>
                <div className="w-[90%] h-[1px] bg-white my-2"></div>
                {/* Copyright claim centered */}
                <p className="text-center text-white font-normal font-primary text-[12px] mb-[10px]">
                    Copyright © All Right Reserved. Netreach Solutions Ltd 2024
                </p>
            </div>
        </div>
      </>
    );
}
