import logo from './logo.svg';
import './App.css';
import Landing from './components/Landing';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Maintainance from './components/Maintainance';
import Web from './components/Web';
import Digital from './components/Digital';
import AI from './components/AI';
import Projects from './components/Projects';
import Services from './components/Services';

function App() {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    // alert(sectionId)
    if (section) {
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  return (
    <div className="App">
      {/* <Maintainance/> */}
      <Landing id='Home' onNavClick={scrollToSection}/>
      <Projects id='About'/>
      <Services id='Services'/>
      <Contact id='Contact Us'/>
      {/* <Footer/> */}
    </div>
  );
}

export default App;
