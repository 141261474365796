import React, { useState } from 'react';
import './Landing.css';

export default function Landing({onNavClick}) {
    const [activeLink, setActiveLink] = useState('Home'); // Track the active link
    const [active, setActive] = useState(false);
    const [open, setOpen] = useState(false);
    const [scrollAnimation, setScrollAnimation] = useState(false);


    window.addEventListener("scroll", () => {
        window.scrollY > 50 ? setScrollAnimation(true) : setScrollAnimation(false);
      });


    const navClickFunc = (value) => {
        setActiveLink(value);
        setOpen(false);
        setActive(false);
        // alert("clicked")
        onNavClick(value);  // Use the passed function
    }

    return (
        <>
            <navbar id='Home' className={`absolute top-0 left-0 width-screen justify-center sm:justify-between z-50 flex w-full px-20 py-5 bg-[#151934] ${scrollAnimation ? "nav-scroll shadow" : ""}`}>
                <div className='flex gap-2 items-center'>
                    <img 
                        src='/images/NetreachLogo_v2.png' 
                        alt='NetReach Logo'
                        className='w-[80px]'
                    />
                    <span className='text-white font-bold text-[20px]'>NetReach</span>
                </div>
                <ul className='text-white items-center hidden md:flex gap-5 text-[18px] absolute left-1/2 transform -translate-y-[20px] -translate-x-1/2 h-full'>
                    {['Home', 'About', 'Services', 'Contact Us'].map((link) => (
                        <li
                            key={link}
                            className={`cursor-pointer ${activeLink === link ? 'opacity-100' : 'opacity-70'}`}
                            onClick={() => navClickFunc(link)} // Set active link on click
                        >
                            {link}
                        </li>
                    ))}
                </ul>
                <button className='bg-[#3288C3] hidden md:block text-white rounded-[50px] px-5 py-3' onClick={() => navClickFunc('Contact Us')}>Get a Demo</button>
            </navbar>

            <main className="relative w-full h-[90vh] bg-gradient-to-r from-[#151934] to-black items-center mt-[100px]"> 
                {/* Background container with overflow hidden */}
                <img 
                    src='/images/HeroImage.png' 
                    alt='vg image'
                    className='w-full absolute '
                />
                <img 
                    src='/images/HeroImage_pt.png' 
                    alt='vg image'
                    className='w-full absolute sm:hidden opacity-75'
                />
                <div className="absolute top-0 left-0 w-full h-full overflow-hidden z-10">
                    <iframe
                        src="https://my.spline.design/lights-9a0d73f6ab9f4c4ade4bcf1c8e61b28c/"
                        frameBorder="0"
                        className="transition duration-300 absolute top-[-20px] left-0 w-[105%] md:h-[120%] h-[120%] z-[-1]"
                        allow="fullscreen"
                        title="Spline 3D Background"
                        zoom='None'
                    ></iframe>
                </div>

                {/* Content Overlay */}
                <div className="w-full h-full flex flex-col justify-center items-center z-10 text-white">
                    <h1 className='text-[35px] md:text-[55px] max-w-[90%] font-semi-bold'>
                        Transforming Digital Landscapes with AI, Web Development, and Digital Marketing
                    </h1>
                    <div className="text-white w-full p-2 italic font-light mb-10 text-center md:text-base text-xs">
                        "Individually, we are one drop. Together, we are an ocean." – Ryunosuke Satoro
                    </div>
                    <button className='z-50 transition duration-300 bg-[#3288C3] text-[18px] text-white rounded-[50px] px-10 py-6 font-medium hover:bg-[#1E5B84] hover:cursor-pointer' onClick={() => navClickFunc('Contact Us')}>Contact Us</button>
                </div>
            </main>
        </>
    );
}
